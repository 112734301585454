<template>
  <v-card :disabled="isProcessing" :loading="isProcessing">
    <template v-slot:progress>
      <v-progress-linear
        absolute
        color="success"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-tabs v-model="tab" class="price-color" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <v-toolbar-title>
          {{ $t("PRICING.LIST") }}
        </v-toolbar-title>
      </v-tab>
      <v-spacer></v-spacer>

      <div class="buttons-tab">
        <v-btn
          color="mr-4 orange"
          v-on:click="createPricing()"
          :loading="isProcessing"
          v-if="hasPermissions('pricing-create')"
          >Add Price List</v-btn
        >
      </div>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <Table
              :path="path"
              tableId="pricings"
              dbTable="pricings"
              :tableInit="tableInit"
              :showParams="showParams"
              @updateRow="updateActive"
              @clone="clone"
              @printView="printView"
              @download="download"
              @downloadRaw="downloadRaw"
              :filtersFields="filtersFields"
            ></Table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <HtmlEditor
      ref="htmlPreview"
      :hideButton="'true'"
      :getContent="getContent"
    ></HtmlEditor>
  </v-card>
</template>

<script>
import {
  TABLE_INIT_URL,
  GET_PRICING_INIT,
  UPDATE_ACTIVE_PRICE,
  GET_PRICING,
  GET_TAG_PRICING,
} from "@/store/pricing.module";
import { GET_ORDERED_MAPPING_VALUES } from "@/store/mappingValues.module";
import PricingService from "@/common/pricing.service.js";
import HtmlEditor from "@/views/partials/content/HtmlEditor.vue";
import Table from "@/views/partials/table/Table.vue";

export default {
  components: {
    Table,
    HtmlEditor,
  },
  name: "ShowPricing",
  data() {
    return {
      isProcessing: false,
      path: TABLE_INIT_URL,
      tableInit: GET_PRICING_INIT,
      tab: "tab-1",
      showParams: { name: "edit-pricing", key: "id" },
      filtersFields: [],
      file: null,
      url: null,
      content: "",
    };
  },

  mounted() {
    this.$store.dispatch(GET_ORDERED_MAPPING_VALUES, 5);
  },

  methods: {
    createPricing() {
      this.$router.push({ name: "create-pricing" });
    },
    updateActive(row) {
      this.$store.dispatch(UPDATE_ACTIVE_PRICE, {
        id: row.id,
        params: {
          is_active: row.is_active,
        },
      });
    },
    clone(row) {
      this.$router.push({
        name: "create-pricing",
        params: { clone_id: row.id },
      });
    },
    printView(row) {
      this.isProcessing = true;
      this.$store.dispatch(GET_PRICING, row.id).then((response) => {
        this.$store.dispatch(GET_TAG_PRICING, response).then((r) => {
          this.isProcessing = false;
          this.content = PricingService.getContent(r.prepared);
          this.$refs.htmlPreview.showDialog();
        });
      });
    },
    getContent() {
      return this.content;
    },
    download(row) {
      this.isProcessing = true;
      this.$store.dispatch(GET_PRICING, row.id).then((response) => {
        this.$store.dispatch(GET_TAG_PRICING, response).then((r) => {
          this.isProcessing = false;
          PricingService.download(response.prepared, response.name);
        });
      });
    },
    downloadRaw(row) {
      this.isProcessing = true;

      this.$store.dispatch(GET_PRICING, row.id).then((response) => {
             this.isProcessing = false;
         PricingService.downloadRaw(response.details,this.$store);
      });
    },
  },
};
</script>

<style>
</style>